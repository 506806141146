import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import Adapter for date handling
import { useEffect, useState } from "react";
import { apiCreateRequestFunds, apiGetRequestFundsById, apiUpdateRequestFunds } from "../../../api/requestFunds";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "../../../utils/utilFunctions";

const CreateEditRequestFunds = ({
    selectedLocation = null,
    user = null,
}) => {
    const navigate = useNavigate(); // Initialize navigate function
    const { fundId } = useParams();

    const [formData, setFormData] = useState({
        amount_requested: 0,
        notes: "",
        payment_date: new Date,
    });

    useEffect(() => {
        console.log("fundId: ", fundId)
        if (fundId && fundId !== "0")
            apiGetRequestFundsById(parseFundResponse, showErrorToast, fundId)
    }, [fundId])

    const parseFundResponse = (data) => {
        setFormData({
            amount_requested: parseFloat(data.amount_requested), // convert to number
            notes: data.notes || "", // set to empty string if null
            payment_date: data.payment_date?new Date(data.payment_date):new Date // convert to JS Date object
        });
        console.log( new Date(data.payment_date) );
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleDateChange = (newDate) => {
        setFormData({
            ...formData,
            payment_date: newDate
        });
    };

    const handleSubmit = async (e) => {
        console.log(fundId);
        e.preventDefault();
        if (fundId === '0') {
            console.log("SUBMIT: ", formData);
            apiCreateRequestFunds(() => { navigate(-1); }, showErrorToast, {...formData})
        } else {
            apiUpdateRequestFunds(() => { navigate(-1); }, showErrorToast, {...formData}, fundId)
        }
    };

    return (
        <>
            <Typography variant="h4">
                <span className="font-bold text-black">Adauga Cerere Fonduri</span>
            </Typography>

            <form onSubmit={handleSubmit}>
                <TextField
                    label="Angajat"
                    name="employee_id"
                    value={user?.name || ""}
                    fullWidth
                    margin="normal"
                    disabled // Disabled since this is a fixed value
                >
                </TextField>
                <TextField
                    label='Suma(RON)'
                    name="amount_requested"
                    type="number"
                    value={formData.amount_requested || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                <Box className="mt-[16px] mb-[8px]">
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                            className="w-full"
                            label="Data la care trebuie banii"
                            value={formData.payment_date}
                            onChange={handleDateChange}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                <TextField
                    label="Notite"
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    required
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                        {fundId === "0" ? 'Adauga Cerere' : 'Actualizeaza'}
                    </Button>
                </Box>
            </form>
        </>
    )
}

export default CreateEditRequestFunds;