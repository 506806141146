import React from 'react';
import { Box, TextField, Grid, Button, Typography, Divider } from '@mui/material';

const VariantSettings = ({ fileInputRef, variant, setVariant, saveVariant }) => {

    const handleChange = (event) => {
        const { name, value } = event.target;
        setVariant({
            ...variant,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        setVariant({
            ...variant,
            photo: event.target.files[0]
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        saveVariant(variant);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Typography variant="h6" sx={{ mb: 2 }}>
                Variant
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="file"
                        label="Upload Photo"
                        name="photo"
                        InputLabelProps={{ shrink: true }}
                        onChange={handleFileChange}
                        margin="normal"
                        inputRef={fileInputRef}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        value={variant.name}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label="SKU"
                        name="sku"
                        value={variant.sku}
                        onChange={handleChange}
                        margin="normal"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        label="Barcode"
                        name="barcode"
                        value={variant.barcode}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        label="Sale Price (RON)"
                        name="sale_price"
                        value={variant.sale_price}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        label="Purchase Price (RON)"
                        name="purchase_price"
                        value={variant.purchase_price}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                { // Additional price fields
                ['weekend_price', 'pret_angajat', 'price_fire', 'price_busy', 'price_standard', 'price_discount'].map(priceType => (
                    <Grid item xs={12} md={6} key={priceType}>
                        <TextField
                            fullWidth
                            type="number"
                            label={priceType.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())} // Convert snake_case to Title Case
                            name={priceType}
                            value={variant[priceType]}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Description"
                        name="description"
                        value={variant.description}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Quantity"
                        name="quantity"
                        value={variant.quantity}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Color"
                        name="color"
                        value={variant.color}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Concentration"
                        name="concentration"
                        value={variant.concentration}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        type="number"
                        label="Duration (min)"
                        name="duration"
                        value={variant.duration}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Difficulty"
                        name="difficulty"
                        value={variant.difficulty}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Size"
                        name="size"
                        value={variant.size}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Seniority"
                        name="seniority"
                        value={variant.seniority}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>Save Variant</Button>
        </Box>
    );
};

export default VariantSettings;
