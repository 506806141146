import { getToken } from "../utils/utilFunctions";

export const apiEditEmployee = async (responseCallback, reqData, employee_id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/editEmployee?employee_id=${employee_id !== undefined ? employee_id : null}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            responseCallback(data)
        } else {
            responseCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        responseCallback({ success: false, message: "Ceva nu a mers bine" })
    }
}

export const apiGetEmployeeBy = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/getEmployeesBy?${reqData}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiGetEmployeeIsPartOfGroup = async (successCallback, errorCallback, groupCode) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/employeePartOfGroup?group_code=${groupCode}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        successCallback(data.success)
    } catch (error) {
        console.error('Error:', error);
        successCallback(false)
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiGetEmployeeGroups = async (successCallback, errorCallback) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/employeeGroups`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        successCallback(false)
        errorCallback("Ceva nu a mers bine")
    }
}


export const apiSearchEmployee = async (successCallback, errorCallback, searchField) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/employeesSearch?searchField=${searchField}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}