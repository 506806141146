import { getToken } from "../utils/utilFunctions";

export const apiGetRights = async (setRights) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/rights/`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
        } else {
            setRights(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const apiGetUserRights = async (setUserRights) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/rights/getUserRights`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
        } else {
            setUserRights(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const apiGetUserRightsBy = async (successCallBack, errorCallBack, employee_id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/rights/getUserRightsBy?${employee_id}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message);
        } else {
            successCallBack(data.data);
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error);
    }
}

export const apiGetUserRightsForLocation = async (successCallBack, errorCallBack, locationId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/rights/getUserRightsForLocation/${locationId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error);
    }
}

export const apiUpdateUserRights = async (responseCallback, userRights) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/rights/updateUserRights`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...userRights
            }),
        });

        const data = await response.json();
        if (!data.success) {
            responseCallback(data)
        } else {
            responseCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        responseCallback({ success: false, message: "Ceva nu a mers bine" })
    }
}

