import { formatDateToYYYYMMDD, getToken } from "../utils/utilFunctions";

export const apiGetPontaje = async (successCallback, errorCallback, location_id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/pontaje/getPontaje/${location_id}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Something went wrong")
    }
}

export const apiCreatePontaj = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    reqData.date=formatDateToYYYYMMDD(reqData.date)
    try {
        const response = await fetch(`${apiUrl}/api/pontaje`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine" )
    }
}