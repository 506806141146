import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

// Sample data for the table
const debtsData = [
    { id: 1, name: "John Doe", amount: 150.00, dueDate: "2024-09-15" },
    { id: 2, name: "Jane Smith", amount: 200.50, dueDate: "2024-09-20" },
    { id: 3, name: "Michael Johnson", amount: 320.75, dueDate: "2024-10-01" },
];

const Debts = () => {
    const navigate = useNavigate(); // Initialize navigate function

    return (
        <>

            <Box className="flex flex-row justify-between">
                <Typography variant="h4">
                    <span className="font-bold text-black">Amenzi/Datorii</span>
                </Typography>
                <Button variant="contained" color="success" onClick={() => { navigate('/dashboard/debts/createDebt') }}>Creeaza Amenda/Datorie</Button>
            </Box>

            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{'ID'}</TableCell>
                            <TableCell>{'Name;'}</TableCell>
                            <TableCell>{'Amount'}</TableCell>
                            <TableCell>{'Due Date'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {debtsData.map((debt) => (
                            <TableRow key={debt.id}>
                                <TableCell>{debt.id}</TableCell>
                                <TableCell>{debt.name}</TableCell>
                                <TableCell>{debt.amount.toFixed(2)} $</TableCell>
                                <TableCell>{debt.dueDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Debts;
