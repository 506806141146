
import {
    Typography,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Box,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { storeToken } from '../utils/utilFunctions';

const Login = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const { t } = useTranslation();
    // State for form fields and errors
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    // Validate form fields
    const validateForm = () => {
        let valid = true;
        let newErrors = {
            email: '',
            password: '',
        };

        if (!email) {
            newErrors.email = t('email-required');
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = t('email-invalid');
            valid = false;
        }

        if (!password) {
            newErrors.password = t('password-required');
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };


    const login = async () => {
        if (!validateForm()) {
            return
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/api/employees/auth/login`, {
                method: 'POST', // Change to 'POST' for sending data
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                }), // Convert your data to a JSON string
            });

            const data = await response.json();
            if (data.success) {
                const token = response.headers.get('X-Auth-Token');
                if (token) {
                    storeToken(token)
                }

                navigate('/dashboard');
            } else {
                showInvalidCredentials()
            }
        } catch (error) {
            console.error('Error:', error);

            toast.error(t('something-went-wrong'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const showInvalidCredentials = () => {

        let newErrors = {
            email: '',
            password: '',
        };

        newErrors.email = t('invalid-credentials');
        newErrors.password = t('invalid-credentials');

        setErrors(newErrors);
    }

    const handleKeyPress = (e) =>{
        if (e.key == 'Enter'){
            login()
        }
    };

    return (
        <>
            <Typography variant="h3">LOGIN</Typography>

            <Typography variant="subtitle1" gutterBottom>
                {t('sign-in-to-account')}
            </Typography>
            <Box component="form" noValidate autoComplete="off" onKeyDown={handleKeyPress}>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label={t('password')}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                {/* <FormControlLabel
                    control={<Checkbox name="rememberMe" />}
                    label={t('remember-me')}
                /> */}
                <Button variant="contained" color="primary" fullWidth onClick={login}>
                    {t('sign-in')}
                </Button>
            </Box>

            {/* <a className="text-blue-500 hover:text-blue-700 underline" href="/auth/register">{t('no-account')}</a> */}
        </>
    );
};

export default Login;
