import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericTable from '../../../components/GenericTable';
import { apiAddOpCard, apiGetFines } from '../../../api/fines';
import { showErrorToast, showSuccessToast } from '../../../utils/utilFunctions';
import CSVReader from 'react-csv-reader';
import { Button } from '@mui/material';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'employee', headerName: 'Employee', type: 'string' },
    { field: 'for_employee', headerName: 'For Employee', type: 'string' },
    { field: 'fine_type', headerName: 'Fine Type', type: 'string' },
    { field: 'price', headerName: 'price', type: 'string' },
    { field: 'description', headerName: 'description', type: 'string' },
    { field: 'created_at', headerName: 'date', type: 'datetime' },
];

const OpCard = ({ selectedLocation, user }) => {
    const navigate = useNavigate(); // Initialize navigate function
    const [data, setData] = useState([])
    const [failedCSVImports, setFailedCSVImports] = useState([])

    useEffect(() => {
        if (selectedLocation)
            apiGetFines(setData, showErrorToast, selectedLocation.id)
    }, [selectedLocation])

    const handleCSVImport = (data, fileInfo) => {

        // Process the CSV data and update the table data
        // Assume that your CSV has the same format as the table's columns
        const processedData = data.map((row, index) => ({
            for_employee_id: row[0],
            fine_type_id: row[1],
            price: row[2],
            description: row[3]
        }));

        processedData.forEach(async (entry, index) => {
            window.dispatchEvent(new CustomEvent("loadingProgress", { detail: { progress: (100/processedData.length*(index+1)) } }));
            if (!entry.for_employee_id || isNaN(Number(entry.for_employee_id))) {
                return;
            }

            const data={...entry, employee_id: user.id, location_id: selectedLocation.id};
            await apiAddOpCard(()=>{}, ()=>{setFailedCSVImports([...failedCSVImports, index+1])}, data)
        })
        if(failedCSVImports.length===0){
            showSuccessToast("Successfull Import")
        }else{
            showErrorToast(`Randurile ${failedCSVImports} nu au fost uploadate`)
        }
        setFailedCSVImports([]);
        apiGetFines(setData, showErrorToast, selectedLocation.id)
    };

    return (
        <>
            <GenericTable importCSV={true} onImportCSV={handleCSVImport} title={"Operatiuni card"} buttonText={"Adauga Op Card"} buttonAction={() => { navigate('/dashboard/opcard/adaugaOperatiuneCard') }} columns={columns} data={data}></GenericTable>
        </>
    );
};

export default OpCard;
