import GenericTable from "../../../components/GenericTable";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../../utils/utilFunctions";
import { getBundlesAPI, toggleBundleAPI } from "../../../api/bundles";

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'title', headerName: 'Title', type: 'string' },
    { field: 'active', headerName: 'Active', type: 'boolean' },
];

const Bundles = () => {
    const [actions, setActions] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData()
        setActions([{ icon: <DeleteIcon />, onClick: handleSetInativeProduct, color: 'red' }])
    }, []);

    const getData = () => {
        getBundlesAPI((response) => { setData(response) }, showErrorToast);
    }

    const handleSetInativeProduct = (id, row) => {
        toggleBundleAPI(getData, showErrorToast, { bundle_id: id, active: !row.active })
    }

    return (
        <>
            <GenericTable
                actions={actions}
                edit={true}
                onEdit={(id) => { navigate(`/app/bundles/create/${id}`) }}
                title={"Bundleuri"}
                buttonText={"Adauga Bundle"}
                buttonAction={() => { navigate('/app/bundles/create/0') }}
                columns={columns}
                data={data}
            />
        </>
    )
}
export default Bundles;