import React, { useState, useEffect } from 'react';
import { TextField, Typography, Box, List, ListItem, CircularProgress } from "@mui/material";
import { apiGetEmployeeDetails } from '../../api/employeeDetails'; // Replace this with your actual API request
import { apiSearchEmployee } from '../../api/employee';
import { showErrorToast } from '../../utils/utilFunctions';
import { useNavigate } from 'react-router-dom';

const Employees = ({
    updateData = () => { console.log("NO UPDATE FUNCTION SET") },
    locations = [],
    departments = [],
    user = {},
    positions = [],
    rights = [],
    userRights = [],
    selectedLocation
}) => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [debounceTimeout, setDebounceTimeout] = useState(null);

    const onEmployeeClick = (employee) => {
        navigate(`/dashboard/employeeEdit/${employee.id}`)
    }
    // Function to fetch data from the server
    const fetchSearchResults = async (search) => {
        setLoading(true);
        try {
            await apiSearchEmployee(setSearchResults, showErrorToast, search);
        } catch (error) {
            console.error("Error fetching employee details:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle the search input change with debouncing
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Clear previous timeout
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout to wait before making the API request
        setDebounceTimeout(setTimeout(() => {
            if (value.trim()) {
                fetchSearchResults(value);
            } else {
                setSearchResults([]); // Clear results if the search is empty
            }
        }, 500)); // 500ms delay for debounce
    };

    // Function to handle clicking on a result
    const handleEmployeeClick = (employee) => {
        onEmployeeClick(employee); // Call the passed function when an employee is clicked
    };

    return (
        <Box className="flex gap-5 flex-col">
            <Typography variant="h5">Angajati</Typography>

            {/* Search Input Field */}
            <TextField
                label="Cauta"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ marginBottom: 2 }}
            />

            {/* Loading Spinner */}
            {loading && <CircularProgress />}

            {/* Search Results */}
            {!loading && searchResults.length > 0 && (
                <List>
                    {searchResults.map((employee) => (
                        <ListItem
                            button
                            key={employee.id}
                            onClick={() => handleEmployeeClick(employee)}
                        >
                            {employee.name} {/* Assuming employee has a `name` property */}
                        </ListItem>
                    ))}
                </List>
            )}

            {/* No Results Message */}
            {!loading && searchResults.length === 0 && searchTerm && (
                <Typography>Nu au fost gasiti angajati</Typography>
            )}
        </Box>
    );
};

export default Employees;