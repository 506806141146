import Login from "./views/Login";
import Register from "./views/Register.js";
import Profile from "./views/dashboard/Profile.js";
import Dashboard from "./views/dashboard/index.js";
import Settings from "./views/dashboard/Settings.js";
import OpCard from "./views/dashboard/opCard/OpCard.js";
import PaymentRequests from "./views/dashboard/paymentRequests/PaymentRequests.js";
import ReceiptSummary from "./views/dashboard/ReceiptSummary.js";
import SalaryWork from "./views/dashboard/SalaryWork.js";
import ManagersActivity from "./views/dashboard/ManagersActivity.js";
import TrainersActivity from "./views/dashboard/trainersActivity/TrainersActivity.js";
import CheckoutRegistry from "./views/dashboard/CheckoutRegistry.js";
import Payroll from "./views/dashboard/Payroll.js";
import PayrollHistory from "./views/dashboard/PayrollHistory.js";
import TrainersStatisitcs from "./views/dashboard/TrainersStatistics.js";
import ManagersStatistiscs from "./views/dashboard/ManagersStatistics.js";
import Debts from "./views/dashboard/debts/Debts.js";
import CreateDebts from "./views/dashboard/debts/CreateDebts.js";
import AddOpCard from "./views/dashboard/opCard/AddOpCard.js";
import CreatePaymentRequest from "./views/dashboard/paymentRequests/CreatePaymentRequest.js";
import Pontaj from "./views/dashboard/pontaj/Pontaj.js";
import CreatePontaj from "./views/dashboard/pontaj/CreatePontaj.js";
import SalonManager from "./views/dashboard/SalonManager.js";
import Employees from "./views/dashboard/Employees.js";
import EmployeeEdit from "./views/dashboard/EmployeeEdit.js";
import RequestFunds from "./views/dashboard/requestFunds/RequestFunds.js";
import CreateEditRequestFunds from "./views/dashboard/requestFunds/CreateEditRequestFunds.js";
import ActivityTable from "./views/dashboard/ActivityTable.js";
import TimeActivityDescription from "./views/dashboard/TimeActivityDescription.js";
import CreateTrainersActivity from "./views/dashboard/trainersActivity/CreateTrainersActivity.js";
import App from "./views/app/index.js";
import AssortmentSettings from "./views/app/AssortmentsSettings.js";
import Products from "./views/app/products/Products.js";
import CreateProducts from "./views/app/products/CreateProducts.js";
import Services from "./views/app/products/Services.js";
import Bundles from "./views/app/bundles/Bundles.js";
import CreateBundle from "./views/app/bundles/CreateBundle.js";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/dashboard",
  },
  {
    path: "/index",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Dashboard,
    layout: "/dashboard",
  },
  {
    path: "/opcard",
    name: "OpCard",
    icon: "ni ni-key-25 text-info",
    component: OpCard,
    layout: "/dashboard",
  },
  {
    path: "/opcard/adaugaOperatiuneCard",
    name: "Adauga Operatiune Card",
    icon: "ni ni-key-25 text-info",
    component: AddOpCard,
    layout: "/dashboard",
  },
  {
    path: "/paymentrequests",
    name: "Payment Requests",
    icon: "ni ni-key-25 text-info",
    component: PaymentRequests,
    layout: "/dashboard",
  },
  {
    path: "/paymentrequests/createPaymentRequest",
    name: "Create Payment Requests",
    icon: "ni ni-key-25 text-info",
    component: CreatePaymentRequest,
    layout: "/dashboard",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-key-25 text-info",
    component: Settings,
    layout: "/dashboard",
  },
  {
    path: "/receiptsummary",
    name: "Receipt Summary",
    icon: "ni ni-key-25 text-info",
    component: ReceiptSummary,
    layout: "/dashboard",
  },
  {
    path: "/salarywork",
    name: "Salary Work",
    icon: "ni ni-key-25 text-info",
    component: SalaryWork,
    layout: "/dashboard",
  },
  {
    path: "/managersactivity",
    name: "Managers Activity",
    icon: "ni ni-key-25 text-info",
    component: ManagersActivity,
    layout: "/dashboard",
  },
  {
    path: "/trainersactivity",
    name: "Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: TrainersActivity,
    layout: "/dashboard",
  },
  {
    path: "/checkoutregistry",
    name: "Registru de Casa",
    icon: "ni ni-key-25 text-info",
    component: CheckoutRegistry,
    layout: "/dashboard",
  },
  {
    path: "/payroll",
    name: "Salarii Luna Curenta",
    icon: "ni ni-key-25 text-info",
    component: Payroll,
    layout: "/dashboard",
  },
  {
    path: "/payrollhistory",
    name: "Istoric Salarii",
    icon: "ni ni-key-25 text-info",
    component: PayrollHistory,
    layout: "/dashboard",
  },
  {
    path: "/trainersstatistics",
    name: "Statistica Traineri",
    icon: "ni ni-key-25 text-info",
    component: TrainersStatisitcs,
    layout: "/dashboard",
  },
  {
    path: "/managersstatistics",
    name: "Statistica Manageri",
    icon: "ni ni-key-25 text-info",
    component: ManagersStatistiscs,
    layout: "/dashboard",
  },
  {
    path: "/debts",
    name: "Datorii",
    icon: "ni ni-key-25 text-info",
    component: Debts,
    layout: "/dashboard",
  },
  {
    path: "/debts/createDebt",
    name: "Datorii",
    icon: "ni ni-key-25 text-info",
    component: CreateDebts,
    layout: "/dashboard",
  },
  {
    path: "/pontaj",
    name: "Pontaj",
    icon: "ni ni-key-25 text-info",
    component: Pontaj,
    layout: "/dashboard",
  },
  {
    path: "/pontaj/createPontaj",
    name: "Create Pontaj",
    icon: "ni ni-key-25 text-info",
    component: CreatePontaj,
    layout: "/dashboard",
  },
  {
    path: "/salonManager",
    name: "Manager Salon",
    icon: "ni ni-key-25 text-info",
    component: SalonManager,
    layout: "/dashboard",
  },
  {
    path: "/employees",
    name: "Manager Salon",
    icon: "ni ni-key-25 text-info",
    component: Employees,
    layout: "/dashboard",
  },
  {
    path: "/employeeEdit/:employee_id",
    name: "Employee Edit",
    icon: "ni ni-key-25 text-info",
    component: EmployeeEdit,
    layout: "/dashboard",
  },
  {
    path: "/requestFunds",
    name: "Cerere Fonduri",
    icon: "ni ni-key-25 text-info",
    component: RequestFunds,
    layout: "/dashboard",
  },
  {
    path: "/requestFunds/createEditRequestFunds/:fundId",
    name: "Creaza Editeaza Request Funds",
    icon: "ni ni-key-25 text-info",
    component: CreateEditRequestFunds,
    layout: "/dashboard",
  },
  {
    path: "/activityTable",
    name: "Creaza Editeaza Request Funds",
    icon: "ni ni-key-25 text-info",
    component: ActivityTable,
    layout: "/dashboard",
  },
  {
    path: "/timeActivityDescription",
    name: "Descrieri Activitati Timp",
    icon: "ni ni-key-25 text-info",
    component: TimeActivityDescription,
    layout: "/dashboard",
  },
  {
    path: "/createTrainersActivity",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: CreateTrainersActivity,
    layout: "/dashboard",
  },

  //APP ROUTES
  {
    path: "/index",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: App,
    layout: "/app",
  },
  {
    path: "/services",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: Services,
    layout: "/app",
  },
  {
    path: "/products",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: Products,
    layout: "/app",
  },
  {
    path: "/assortmentSettings",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: AssortmentSettings,
    layout: "/app",
  },
  {
    path: "/products/create/:productId",
    name: "Create Trainers Activity",
    icon: "ni ni-key-25 text-info",
    component: CreateProducts,
    layout: "/app",
  },
  {
    path: "/bundles",
    name: "Bundles",
    icon: "ni ni-key-25 text-info",
    component: Bundles,
    layout: "/app",
  },
  {
    path: "/bundles/create/:bundleId",
    name: "Bundles",
    icon: "ni ni-key-25 text-info",
    component: CreateBundle,
    layout: "/app",
  },
];
export default routes;
