import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const Payroll = () => {
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h4">
                <span className="font-bold text-black">Payroll</span>
            </Typography>
        </>
    )
}
export default Payroll;