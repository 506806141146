import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import roTranslation from './locales/ro/translation.json';

// Retrieve the language from localStorage, defaulting to 'en' if not found
const savedLanguage = 'ro';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      ro: {
        translation: roTranslation
      },
    },
    lng: savedLanguage, // Use the saved language from localStorage
    fallbackLng: 'ro',  // Fallback language if translation is missing
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

export default i18n;
