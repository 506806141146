import { getToken } from "../utils/utilFunctions";

export const apiGetPositions = async (setPositions) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
      const response = await fetch(`${apiUrl}/api/positions/`, {
        method: 'GET', // or 'GET' depending on your APIS
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (!data.success) {
      }else{
        setPositions(data.data)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }