import React, { useEffect, useRef, useState } from 'react';
import { Box, Tab, Tabs, Button } from '@mui/material';
import TabPanel from '../../../components/TabPanel';
import ProductSettings from '../../../components/products/ProductSettings';
import VariantSettings from '../../../components/products/VariantSettings';
import GenericTable from '../../../components/GenericTable';
import { showErrorToast, showSuccessToast } from '../../../utils/utilFunctions';
import { createProductAPI, createProductVariantAPI, deactivateProductVariantAPI, getProductByIdAPI, getProductsAPI, getProductVariantsAPI, updateProductAPI, updateProductVariantAPI } from '../../../api/products';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';


const columns = [
    { field: 'photo', headerName: 'photo', type: 'filepath' },
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'sale_price', headerName: 'Pret (RON)', type: 'string' },
    { field: 'name', headerName: 'Denumire', type: 'string' },
    { field: 'barcode', headerName: 'Barcode', type: 'string' },
    { field: 'sku', headerName: 'SKU', type: 'string' },
];

const variantInitialValue = {
    id: null,
    sku: '',
    name: '',
    barcode: '',
    sale_price: '',
    purchase_price: '',
    weekend_price: '',
    pret_angajat: '',
    price_fire: '',
    price_busy: '',
    price_standard: '',
    price_discount: '',
    description: '',
    quantity: '',
    color: '',
    concentration: '',
    duration: '',
    difficulty: '',
    size: '',
    seniority: '',
    photo: '',
    active: true,
    product_id: '',
}


const CreateProducts = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const [tabIndex, setTabIndex] = useState(0);
    const { productId } = useParams();
    const [product, setProduct] = useState({
        id: null,
        photo: null,
        name: '',
        brand_id: '',
        supplier_id: '',
        base_sku: '',
        tva_bracket: 19, // Defaulted to 19
        sell_type: '',
        unit_type_id: '',
        type: '',
        product_category_id: '',
    });
    const [variant, setVariant] = useState(variantInitialValue);
    const [productVariants, setProductVariants] = useState([])
    const [actions, setActions] = useState([])
    const fileInputRef = useRef(null);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(()=>{
        if(!productId || productId == 0){
            return;
        }else{
            getProductByIdAPI((data)=>{setProduct(data)}, showErrorToast, productId)
            setVariant({...variant, product_id: productId})
        }
    }, [])

    useEffect(() => {
        if (product.id) {
            getVariantsData()
        }
        setActions([{ icon: <DeleteIcon />, onClick: handleDeleteProductVariant, color: 'red' }])
    }, [product])

    const getVariantsData = () => {
        getProductVariantsAPI(setProductVariants, showErrorToast, product.id)
    }

    const handleDeleteProductVariant = (id) => {
        deactivateProductVariantAPI(() => {
            getVariantsData();
        }, showErrorToast, { id })
    }

    // Function to handle product creation
    const handleCreateProduct = () => {
        console.log("productId: ", productId);
        if(!product.id || product.id === "0"){
            showErrorToast("Nu ati salvat niciun produs")
            return;
        }
        if(productVariants.length === 0){
            showErrorToast("Nu ati salvat nicio varianta")
            return;
        }
        navigate(-1);
    };

    const handleSetProduct = (tempProduct) => {
        const elements = [
            tempProduct.name && tempProduct.name.replace(/\s+/g, ''),
            tempProduct.brand_id && tempProduct.brand_id.toString(),
            tempProduct.supplier_id && tempProduct.supplier_id.toString(),
            tempProduct.type && tempProduct.type.toString(),
        ].filter(element => element);

        tempProduct.base_sku = elements.filter(el => el).join('-').toUpperCase();
        setProduct(tempProduct)
    }

    const handleSetVariant = (tempVariant) => {
        if (!product.id) {
            showErrorToast("Salvati produsul mai intai!")
            return;
        }
        const elements = [
            product.base_sku,
            tempVariant.name && tempVariant.name.substring(0, 5),
            tempVariant.color && tempVariant.color.substring(0, 3),
            tempVariant.size && tempVariant.size.toString(),
            tempVariant.concentration && tempVariant.concentration.toString(),
            tempVariant.difficulty && tempVariant.difficulty.toString(),
            tempVariant.quantity && tempVariant.quantity.toString(),
            tempVariant.seniority && tempVariant.seniority.toString(),
        ].filter(element => element);
        // Combine elements with hyphens and convert to uppercase to form the SKU
        tempVariant.sku = elements.filter(el => el).join('-').toUpperCase();
        setVariant(tempVariant)
    }

    const saveVariant = () => {
        if (!variant.id) {
            createProductVariantAPI(() => {
                getVariantsData();
                setVariant({ ...variantInitialValue, product_id: product.id });
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Clear the current file input
                }
            }, showErrorToast, variant)
        } else {
            updateProductVariantAPI(() => {
                getVariantsData();
                setVariant({ ...variantInitialValue, product_id: product.id });
                if (fileInputRef.current) {
                    fileInputRef.current.value = ""; // Clear the current file input
                }
            }, showErrorToast, variant)
        }
    }

    const saveProduct = () => {
        if (!product.id) {
            createProductAPI((data) => {
                setProduct({ ...product, id: data.data.id });
                setVariant({ ...variant, product_id: data.data.id });
                getVariantsData();
                showSuccessToast(data.message);
            }, showErrorToast, product);
        } else {
            updateProductAPI((data) => {
                setProduct({ ...product });
                showSuccessToast(data.message);
            }, showErrorToast, product);
        }
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="product tabs">
                        <Tab label="Product/Service" />
                        <Tab label="Variants" />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <ProductSettings product={product} setProduct={handleSetProduct} saveProduct={saveProduct} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <GenericTable
                        title={''}
                        edit={true}
                        onEdit={(id) => { setVariant(productVariants.filter(variant => id===variant.id)[0])}}
                        columns={columns}
                        data={productVariants}
                        actions={actions} />
                    <VariantSettings fileInputRef={fileInputRef} variant={variant} setVariant={handleSetVariant} saveVariant={saveVariant} />
                </TabPanel>
                <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateProduct}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default CreateProducts;
