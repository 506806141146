import { getToken } from "../utils/utilFunctions";

export const apiGetRequestFunds = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/requestFunds/getRequestFunds/`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const apiGetRequestFundsById = async (successCallBack, errorCallBack, fundId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/requestFunds/getRequestFundById?fund_id=${fundId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}


export const apiCreateRequestFunds = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    reqData.payment_date = reqData.payment_date.toISOString().slice(0, 19).replace('T', ' ');
    try {
        const response = await fetch(`${apiUrl}/api/requestFunds/createRequestFund`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiChangeRequestFundStatus = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/requestFunds/changeRequestFundStatus/${reqData.fundId}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                status: reqData.status,
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiUpdateRequestFunds = async (successCallback, errorCallback, reqData, fundId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    reqData.payment_date = reqData.payment_date.toISOString().slice(0, 19).replace('T', ' ');
    try {
        const response = await fetch(`${apiUrl}/api/requestFunds/updateRequestFund/${fundId}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiPayRequestFunds = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const formData = new FormData();
        
        // Append the status and the file (sent_proof) to the formData
        formData.append('status', reqData.status);
        if (reqData.sent_proof) {
            formData.append('sent_proof', reqData.sent_proof); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/requestFunds/changeRequestFundStatus/${reqData.fundId}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiCompleteRequestFunds = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const formData = new FormData();
        
        formData.append('status', reqData.status);
        if (reqData.usage_proof) {
            formData.append('usage_proof', reqData.usage_proof);
        }

        const response = await fetch(`${apiUrl}/api/requestFunds/changeRequestFundStatus/${reqData.fundId}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}