import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GenericTable from '../GenericTable';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utils/utilFunctions';
import { createAssortmentBrand, deleteAssortmentBrand, getAssortmentBrands } from '../../api/assortmentSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'name', headerName: 'Nume', type: 'string' },
    { field: 'code', headerName: 'COD', type: 'string' },
];

const Brands = () => {
    const navigate = useNavigate();
    const [actions, setActions] = useState([]);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newBrand, setNewBrand] = useState({ name: '', code: '' });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(null);

    useEffect(() => {
        getAssortmentBrands(setData, showErrorToast);
        setActions([{ icon: <DeleteIcon />, onClick: handleOpenDeleteDialog, color: 'red' }])
    }, []);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewBrand({ name: '', code: '', for_product_type: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBrand((prev) => ({ ...prev, [name]: value }));
    };

    const handleSuccessAssortmentBrand = () => {
        setOpenDialog(false);
        setNewBrand({ name: '', code: '', for_product_type: '' });
        getAssortmentBrands(setData, showErrorToast);
    }

    const handleSubmit = () => {
        createAssortmentBrand(handleSuccessAssortmentBrand, showErrorToast, newBrand)
    };

    const handleDeleteBrand = () => {
        deleteAssortmentBrand(handleSuccessAssortmentBrand, showErrorToast, { id: selectedBrandId })
        setOpenDeleteDialog(false);
    };
    
    const handleOpenDeleteDialog = (id) => {
        setSelectedBrandId(id)
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <GenericTable
                actions={actions}
                edit={false}
                onEdit={(id) => { navigate(`/dashboard/requestFunds/createEditRequestFunds/${id}`); }}
                columns={columns}
                data={data}
                title="Brands"
                buttonText={"Adauga Brand"}
                buttonAction={handleDialogOpen}
            />

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Adauga Brand Nou</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Nume"
                        fullWidth
                        value={newBrand.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="code"
                        label="COD"
                        fullWidth
                        value={newBrand.code}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <DeleteConfirmationDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={handleDeleteBrand}
                title="Confirmare Ștergere"
                message="Sunteți sigur că doriți să ștergeți acest brand?"
            />
        </>
    );
};

export default Brands;