import { useState } from "react";
import { Typography, Tabs, Tab, Box } from "@mui/material";
import Brands from "../../components/assortmentComponents.js/Brands";
import Categories from "../../components/assortmentComponents.js/Categories";
import Suppliers from "../../components/assortmentComponents.js/Suppliers";
import TabPanel from "../../components/TabPanel";

const AssortmentSettings = () => {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                <span className="font-bold text-black">SETARI SORTIMENTE</span>
            </Typography>

            <Tabs 
                value={tabIndex} 
                onChange={handleTabChange} 
                aria-label="Assortment Settings Tabs" 
                textColor="primary" 
                indicatorColor="primary"
            >
                <Tab label="Categories" />
                <Tab label="Brands" />
                <Tab label="Suppliers" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
                <Categories></Categories>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <Brands></Brands>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <Suppliers></Suppliers>
            </TabPanel>
        </Box>
    );
};

export default AssortmentSettings;
