import GenericTable from "../../../components/GenericTable";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../../utils/utilFunctions";
import { deactivateProductAPI, getProductsAPI } from "../../../api/products";

const columns = [
    { field: 'photo', headerName: 'photo', type: 'filepath' },
    { field: 'variants_count', headerName: 'Variants', type: 'int_greater_0' },
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'name', headerName: 'Denumire', type: 'string' },
    { field: 'brand_name', headerName: 'Brand', type: 'string' },
];

const Services = () => {
    const [actions, setActions] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
        setActions([{ icon: <DeleteIcon />, onClick: handleDeleteProduct, color: 'red' }])
    }, []);

    const getData = () => {
        getProductsAPI(setData, showErrorToast, "Service");
    }

    const handleDeleteProduct = (product) => {
        deactivateProductAPI(getData, showErrorToast, {id: product})
    }

    return (
        <>
            <GenericTable
                actions={actions}
                edit={true}
                onEdit={(id) => { navigate(`/app/products/create/${id}`) }}
                title={"Servicii"}
                buttonText={"Adauga Produs Sau Serviciu"}
                buttonAction={() => { navigate('/app/products/create/0') }}
                columns={columns}
                data={data}
            />
        </>
    )
}
export default Services;