import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, title = "Confirmare Ștergere", message = "Sunteți sigur că doriți să ștergeți acest element?" }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="error">
                    Șterge
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};

export default DeleteConfirmationDialog;