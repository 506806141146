import { useEffect, useState } from "react";
import GenericTable from "../../../components/GenericTable";
import { useNavigate } from "react-router-dom";
import { apiGetPaymentRequests } from "../../../api/paymentRequests";
import { showErrorToast } from "../../../utils/utilFunctions";

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'employee', headerName: 'Employee', type: 'string' },
    { field: 'for_employee', headerName: 'For Employee', type: 'string' },
    { field: 'request_type', headerName: 'Fine Type', type: 'string' },
    { field: 'price', headerName: 'price', type: 'string' },
    { field: 'description', headerName: 'description', type: 'string' },
    { field: 'created_at', headerName: 'date', type: 'datetime' },
];

const PaymentRequests = ({selectedLocation}) => {
    const navigate = useNavigate(); // Initialize navigate function
    const [data, setData] = useState([])


    useEffect(() => {
        if (selectedLocation)
            apiGetPaymentRequests(setData, showErrorToast, selectedLocation.id)
    }, [selectedLocation])

    return (
        <>
            <GenericTable title={"Cereri de plata"} buttonText={"Adauga Cerere"} buttonAction={() => { navigate('/dashboard/paymentrequests/createPaymentRequest') }} columns={columns} data={data}></GenericTable>
        </>
    )
}
export default PaymentRequests;