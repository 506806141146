import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiChangeRequestFundStatus, apiCompleteRequestFunds, apiGetRequestFunds, apiPayRequestFunds } from "../../../api/requestFunds";
import GenericTable from "../../../components/GenericTable";
import { showErrorToast, showSuccessToast } from "../../../utils/utilFunctions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddCardIcon from '@mui/icons-material/AddCard';
import PendingIcon from '@mui/icons-material/Pending';
import { apiGetEmployeeIsPartOfGroup } from "../../../api/employee";
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Box
} from '@mui/material';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'requester_name', headerName: 'Cerut De', type: 'string' },
    { field: 'amount_requested', headerName: 'Suma', type: 'string' },
    { field: 'status', headerName: 'Status', type: 'status' },
    { field: 'approver_name', headerName: 'Aprobat de', type: 'string' },
    { field: 'approval_date', headerName: 'Aprobat la data', type: 'date' },
    { field: 'payment_date', headerName: 'Pentru data de', type: 'date' },
    { field: 'notes', headerName: 'Notite', type: 'string' },
    { field: 'sent_proof', headerName: 'Dovada suma trimisa', type: 'filepath' },
    { field: 'usage_proof', headerName: 'Dovada plata', type: 'filepath' },
    { field: 'created_at', headerName: 'created_at', type: 'date' },
];

const RequestFunds = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isCFA, setIsCFA] = useState(false);
    const [actions, setActions] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);  // For the modal
    const [file, setFile] = useState(null);
    const [dialogId, setDialogId] = useState(null);
    const [dialogContent, setDialogContent] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogType, setDialogType] = useState('');

    const statuses = [
        { icon: <PendingIcon style={{ color: 'gray' }} />, name: "pending" },
        { icon: <CheckCircleIcon style={{ color: 'green' }} />, name: "approved" },
        { icon: <CancelIcon style={{ color: 'red' }} />, name: "rejected" },
        { icon: <AddCardIcon style={{ color: 'green' }} />, name: "money sent" },
        { icon: <AddTaskIcon style={{ color: 'gray' }} />, name: "completed" },
    ];

    useEffect(() => {
        apiGetRequestFunds(setData, showErrorToast);
        apiGetEmployeeIsPartOfGroup(setIsCFA, showErrorToast, 'CFA');
    }, []);

    useEffect(() => {
        let actionsTmp = [
            ...(isCFA ? [{ icon: <CheckCircleIcon />, onClick: handleApproveFundRequest, color: 'green' }] : []),
            ...(isCFA ? [{ icon: <CancelIcon />, onClick: handleRejectFundRequest, color: 'red' }] : []),
            ...(isCFA ? [{ icon: <AddCardIcon />, onClick: handleSetPayedFundRequest, color: 'black' }] : []),
            { icon: <AddTaskIcon />, onClick: handleSetCompleteFundRequest, color: 'black' },
        ];
        setActions(actionsTmp);
    }, [isCFA]);

    // Open the dialog
    const handleOpenDialog = (id, dialogTitle, dialogContent, dialogType) => {
        setDialogContent(dialogContent)
        setDialogTitle(dialogTitle)
        setDialogId(id)
        setDialogType(dialogType)
        setDialogOpen(true);
    };

    // Close the dialog
    const handleCloseDialog = () => {
        setDialogContent('')
        setDialogTitle('')
        setDialogId(null)
        setDialogType('')
        setDialogOpen(false);
        setFile(null);
    };

    // Handle file change
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle confirmation
    const handleConfirm = () => {
        if (dialogType === 'pay') {
            apiPayRequestFunds(handleChangeStateSuccessfull, showErrorToast, { status: 'money sent', fundId: dialogId, sent_proof: file ? file : null })
        } else if (dialogType === 'complete') {
            // Process the file and change status
            if (!file) {
                showErrorToast("Dovada platii este obligatorie")
                return;
            }
            apiCompleteRequestFunds(handleChangeStateSuccessfull, showErrorToast, { status: 'completed', fundId: dialogId, usage_proof: file })
            handleCloseDialog()
        }

    };

    const handleApproveFundRequest = (id) => {
        apiChangeRequestFundStatus(handleChangeStateSuccessfull, showErrorToast, { status: 'approved', fundId: id });
    };

    const handleRejectFundRequest = (id) => {
        apiChangeRequestFundStatus(handleChangeStateSuccessfull, showErrorToast, { status: 'rejected', fundId: id });
    };

    const handleSetPayedFundRequest = (id) => {
        handleOpenDialog(id, "Seteaza plata", "Dovada platii (poza sau fisier cu transferul daca este cazul)", 'pay');
    };

    const handleSetCompleteFundRequest = (id) => {
        handleOpenDialog(id, "Finalizeaza cererea", "Dovada platii (Bon/facutra) (poza/fisier)", 'complete');
    };

    const handleChangeStateSuccessfull = (data) => {
        showSuccessToast(data.message);
        apiGetRequestFunds(setData, showErrorToast);
        handleCloseDialog()
    };

    return (
        <>
            <GenericTable
                statuses={statuses}
                actions={actions}
                edit={true}
                onEdit={(id) => { navigate(`/dashboard/requestFunds/createEditRequestFunds/${id}`) }}
                title={"Cerere Fonduri"}
                buttonText={"Adauga Cerere"}
                buttonAction={() => { navigate('/dashboard/requestFunds/createEditRequestFunds/0') }}
                columns={columns}
                data={data}
            />

            {/* Dialog for confirmation and file upload */}
            {dialogOpen && (
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <Box>
                            <p>{dialogContent}</p>
                            <input
                                type="file"
                                accept="image/*,.pdf"
                                onChange={handleFileChange}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
                        <Button onClick={handleConfirm} color="primary">Confirma</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default RequestFunds;
