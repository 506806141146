import React, { useEffect, useState } from 'react';
import { Typography, MenuItem, Select, FormControl, InputLabel, Autocomplete, TextField, Box, Button, IconButton } from "@mui/material";
import { apiGetTimeActivityDescription, createDepartmentActivities, deleteDepartmentActivities, getDepartmentActivities } from '../../api/activityTable';
import { showErrorToast, showSuccessToast } from '../../utils/utilFunctions';
import DeleteIcon from '@mui/icons-material/Delete';

const TimeActivityDescription = ({ departments }) => {
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [activities, setActivities] = useState([]);
    const [timeActivityDescriptions, setTimeActivityDescriptions] = useState([]);
    const [selectedActivityName, setSelectedActivityName] = useState('');
    const [selectedActivityId, setSelectedActivityId] = useState('');

    // Fetch activities when a department is selected using fetch
    const fetchActivities = async () => {
        await getDepartmentActivities(setActivities, showErrorToast, selectedDepartment)
    };

    const onAutocompleteChange = (event, value) => {
        if (typeof value === 'string') {
            // This means the user typed a value not in the list
            setSelectedActivityName(value);
            setSelectedActivityId(null);
        } else if (value && value.name) {
            // This means the user selected an option from the list
            setSelectedActivityName(null);
            setSelectedActivityId(value.id);
        } else {
            setSelectedActivityName(null); // Clear if no value
            setSelectedActivityId(null);
        }
    };

    useEffect(() => {
        apiGetTimeActivityDescription(setTimeActivityDescriptions, showErrorToast);
    }, []);

    // Handle department selection
    const handleDepartmentChange = (event) => {
        const departmentId = event.target.value;
        setSelectedDepartment(departmentId);
    };

    useEffect(() => {
        fetchActivities();
    }, [selectedDepartment])

    const handleSubmit = async () => {
        // Make a request with selectedActivity, whether it's from the list or user input
        const dataRequest = {
            department_id: selectedDepartment,
            ...(selectedActivityName ? { activity_name: selectedActivityName } : {}),
            ...(selectedActivityId ? { activity_id: selectedActivityId } : {})
        };
        await createDepartmentActivities(successCallBack, showErrorToast, dataRequest)
    };

    const successCallBack = (message) => {
        showSuccessToast(message);
        fetchActivities();
    }

    const handleDeleteDepartmentActivity = (activityId) => {
        deleteDepartmentActivities(successCallBack, showErrorToast, selectedDepartment, activityId)
    }

    return (
        <>
            <Typography variant="h4">
                <span className="font-bold text-black">TimeActivityDescription</span>
            </Typography>

            {/* Dropdown to select a department */}
            <FormControl fullWidth margin="normal">
                <InputLabel id="department-select-label">Select Department</InputLabel>
                <Select
                    label="Select Department"
                    labelId="department-select-label"
                    value={selectedDepartment}
                    name="name"
                    onChange={handleDepartmentChange}
                >
                    {departments.map((department) => (
                        <MenuItem key={department.id} name={department.name} value={department.id}>
                            {department.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Display activities for the selected department */}
            <div>
                {activities.length > 0 ? (
                    activities.map((activity) => (
                        <Box key={activity.activity_id} display="flex" alignItems="center">
                            <Typography variant="body1" style={{ marginRight: '10px' }}>
                                {activity.activity_name}
                            </Typography>
                            <IconButton
                                color="secondary"
                                onClick={() => handleDeleteDepartmentActivity(activity.activity_id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))
                ) : (
                    selectedDepartment && (
                        <Typography variant="body1" color="textSecondary">
                            No activities found for this department
                        </Typography>
                    )
                )}
            </div>

            {selectedDepartment &&
                <Box className="mt-5 flex justify-between">
                    <Autocomplete
                        disablePortal
                        freeSolo // Allows free input
                        getOptionLabel={(option) => option.name}
                        options={timeActivityDescriptions}
                        onChange={onAutocompleteChange}
                        onInputChange={onAutocompleteChange}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Activitate" />}
                    />
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Adauga
                    </Button>
                </Box>
            }
        </>
    );
};

export default TimeActivityDescription;
