import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GenericTable from '../GenericTable';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utils/utilFunctions';
import { createAssortmentSupplier, deleteAssortmentSupplier, getAssortmentSuppliers } from '../../api/assortmentSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'name', headerName: 'Nume', type: 'string' },
    { field: 'code', headerName: 'COD', type: 'string' },
];

const Suppliers = () => {
    const navigate = useNavigate();
    const [actions, setActions] = useState([]);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newSupplier, setNewSupplier] = useState({ name: '', code: '' });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedSupplierId, setSelectedSupplierId] = useState(null);

    useEffect(() => {
        getAssortmentSuppliers(setData, showErrorToast);
        setActions([{ icon: <DeleteIcon />, onClick: handleOpenDeleteDialog, color: 'red' }]);
    }, []);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewSupplier({ name: '', code: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSupplier((prev) => ({ ...prev, [name]: value }));
    };

    const handleSuccessAssortmentSupplier = () => {
        setOpenDialog(false);
        setNewSupplier({ name: '', code: '' });
        getAssortmentSuppliers(setData, showErrorToast);
    };

    const handleSubmit = () => {
        createAssortmentSupplier(handleSuccessAssortmentSupplier, showErrorToast, newSupplier);
    };


    const handleDeleteSupplier = () => {
        deleteAssortmentSupplier(handleSuccessAssortmentSupplier, showErrorToast, { id: selectedSupplierId });
        setOpenDeleteDialog(false);
    };
    
    const handleOpenDeleteDialog = (id) => {
        setSelectedSupplierId(id)
        setOpenDeleteDialog(true);
    };

    return (
        <>
            <GenericTable
                actions={actions}
                edit={false}
                onEdit={(id) => { navigate(`/dashboard/requestFunds/createEditRequestFunds/${id}`); }}
                columns={columns}
                data={data}
                title="Suppliers"
                buttonText={"Adauga Furnizor"}
                buttonAction={handleDialogOpen}
            />

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Adauga Furnizor Nou</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Nume"
                        fullWidth
                        value={newSupplier.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="code"
                        label="COD"
                        fullWidth
                        value={newSupplier.code}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Delete Confirmation Dialog */}
            <DeleteConfirmationDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={handleDeleteSupplier}
                title="Confirmare Ștergere"
                message="Sunteți sigur că doriți să ștergeți acest furnizor?"
            />
        </>
    );
};

export default Suppliers;
