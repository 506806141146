import React, { useEffect } from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Button } from '@mui/material';
import moment from 'moment';

const BundleSettings = ({ bundle = {}, setBundle, saveBundle }) => {
    const handleChange = (event) => {
        const { name, value } = event.target;
        setBundle((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    const handleDateChange = (newDate) => {
        setBundle((prev) =>({
            ...prev,
            date: new Date(newDate.target.value)
        }));
    };

    useEffect(() => {
        console.log('bundle: ', bundle)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        saveBundle(bundle);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        value={bundle.title}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        value={bundle.description}
                        onChange={handleChange}
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="date-type-label">Date Type</InputLabel>
                        <Select
                            labelId="date-type-label"
                            label="Date Type"
                            id="date_type"
                            name="date_type"
                            value={bundle.date_type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="nodate">No Date</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="datetime">Datetime</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id="date"
                        label="Date"
                        name="date"
                        type={bundle.date_type === 'datetime' ? 'datetime-local' : 'date'}
                        value={
                            bundle.date_type === 'datetime' ? moment(bundle.date).format('YYYY-MM-DDTHH:mm') : moment(bundle.date).format('YYYY-MM-DD')
                        }
                        onChange={handleDateChange}
                        margin="normal"
                        disabled={bundle.date_type === 'nodate' || !bundle.date_type}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="discount-type-label">Discount Type</InputLabel>
                        <Select
                            label="Discount Type"
                            labelId="discount-type-label"
                            id="discount_type"
                            name="discount_type"
                            value={bundle.discount_type}
                            onChange={handleChange}
                        >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="absolute">Absolute</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        id="discount_value"
                        label="Discount Value"
                        name="discount_value"
                        value={bundle.discount_value || ''}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Save Bundle
            </Button>
        </Box>
    );
};

export default BundleSettings;
