import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import GenericTable from '../GenericTable';
import { useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utils/utilFunctions';
import { createAssortmentCategory, deleteAssortmentCategory, getAssortmentCategories } from '../../api/assortmentSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'name', headerName: 'Nume', type: 'string' },
    { field: 'code', headerName: 'COD', type: 'string' },
    { field: 'for_product_type', headerName: 'For Product Type', type: 'string' },
];

const Categories = () => {
    const navigate = useNavigate();
    const [actions, setActions] = useState([]);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newCategory, setNewCategory] = useState({ name: '', code: '', for_product_type: '' });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    useEffect(() => {
        getAssortmentCategories(setData, showErrorToast);
        setActions([{ icon: <DeleteIcon />, onClick: handleOpenDeleteDialog, color: 'red' }])
    }, []);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewCategory({ name: '', code: '', for_product_type: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCategory((prev) => ({ ...prev, [name]: value }));
    };

    const handleTypeChange = (e) => {
        setNewCategory((prev) => ({ ...prev, for_product_type: e.target.value }));
    };

    const handleSuccessAssortmentCategory = () => {
        setOpenDialog(false);
        setNewCategory({ name: '', code: '', for_product_type: '' });
        getAssortmentCategories(setData, showErrorToast);
    }

    const handleSubmit = () => {
        createAssortmentCategory(handleSuccessAssortmentCategory, showErrorToast, newCategory)
    };

    const handleDeleteCategory = () => {
        deleteAssortmentCategory(handleSuccessAssortmentCategory, showErrorToast, { id: selectedCategoryId });
        setOpenDeleteDialog(false);
    };
    
    const handleOpenDeleteDialog = (id) => {
        setSelectedCategoryId(id);
        setOpenDeleteDialog(true);
    };
    return (
        <>
            <GenericTable
                actions={actions}
                edit={false}
                onEdit={(id) => { navigate(`/dashboard/requestFunds/createEditRequestFunds/${id}`); }}
                columns={columns}
                data={data}
                title="Categories"
                buttonText={"Adauga Categorie"}
                buttonAction={handleDialogOpen}
            />

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Adauga Categorie Noua</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Nume"
                        fullWidth
                        value={newCategory.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        name="code"
                        label="COD"
                        fullWidth
                        value={newCategory.code}
                        onChange={handleInputChange}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="type-label">For Product Type</InputLabel>
                        <Select
                            label="For Product Type"
                            labelId="type-label"
                            value={newCategory.for_product_type}
                            onChange={handleTypeChange}
                        >
                            <MenuItem value="Service">Service</MenuItem>
                            <MenuItem value="Product">Product</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            
            {/* Delete Confirmation Dialog */}
            <DeleteConfirmationDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={handleDeleteCategory}
                title="Confirmare Ștergere"
                message="Sunteți sigur că doriți să ștergeți această categorie?"
            />
        </>
    );
};

export default Categories;