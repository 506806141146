import React, { useState, useEffect } from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Button } from '@mui/material';
import { getAssortmentBrands, getAssortmentCategories, getAssortmentSuppliers, getUnitTypes } from '../../api/assortmentSettings';
import { showErrorToast, showSuccessToast } from '../../utils/utilFunctions';

const ProductSettings = ({ product, setProduct, saveProduct }) => {
    const [brands, setBrands] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [categories, setCategories] = useState([]);

    // Fetching brands, suppliers, unit types, and categories from the server
    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        getAssortmentBrands(setBrands, showErrorToast)
        getAssortmentCategories(setCategories, showErrorToast)
        getAssortmentSuppliers(setSuppliers, showErrorToast)
        getUnitTypes(setUnitTypes, showErrorToast)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProduct({
            ...product,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        setProduct({
            ...product,
            photo: event.target.files[0]
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        saveProduct(product);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="file"
                        id="photo"
                        name="photo"
                        onChange={handleFileChange}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        label="Upload Photo"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        value={product.name}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="brand-label">Brand</InputLabel>
                        <Select
                            labelId="brand-label"
                            id="brand_id"
                            name="brand_id"
                            value={product.brand_id}
                            label="Brand"
                            onChange={handleChange}
                            required
                        >
                            {brands.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="supplier-label">Supplier</InputLabel>
                        <Select
                            labelId="supplier-label"
                            id="supplier_id"
                            name="supplier_id"
                            value={product.supplier_id}
                            label="Supplier"
                            onChange={handleChange}
                            required
                        >
                            {suppliers.map((supplier) => (
                                <MenuItem key={supplier.id} value={supplier.id}>{supplier.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        id="base_sku"
                        label="Base SKU"
                        name="base_sku"
                        value={product.base_sku}
                        onChange={handleChange}
                        margin="normal"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        id="tva_bracket"
                        label="TVA Bracket"
                        name="tva_bracket"
                        value={product.tva_bracket}
                        onChange={handleChange}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="sell-type-label">Sell Type</InputLabel>
                        <Select
                            labelId="sell-type-label"
                            id="sell_type"
                            name="sell_type"
                            value={product.sell_type}
                            label="Sell Type"
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="use">Use</MenuItem>
                            <MenuItem value="resell">Resell</MenuItem>
                            <MenuItem value="use&resell">Use & Resell</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="unit-type-label">Unit Type</InputLabel>
                        <Select
                            labelId="unit-type-label"
                            id="unit_type_id"
                            name="unit_type_id"
                            value={product.unit_type_id}
                            label="Unit Type"
                            onChange={handleChange}
                            required
                        >
                            {unitTypes.map((unitType) => (
                                <MenuItem key={unitType.id} value={unitType.id}>{unitType.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type"
                            name="type"
                            value={product.type}
                            label="Type"
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="Product">Product</MenuItem>
                            <MenuItem value="Service">Service</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="product-category-label">Product Category</InputLabel>
                        <Select
                            labelId="product-category-label"
                            id="product_category_id"
                            name="product_category_id"
                            value={product.product_category_id}
                            label="Product Category"
                            onChange={handleChange}
                            required
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>Save product</Button>
        </Box>
    );
};

export default ProductSettings;