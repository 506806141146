import { useState, useEffect } from "react";
import { Typography, Button, CircularProgress, MenuItem, Select, TextField, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import Adapter for date handling
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';  // To format date and set times
import { TRAINERS_POSITIONS } from "../../../utils/utilConstants";
import { apiGetEmployeeBy } from "../../../api/employee";
import { showErrorToast } from "../../../utils/utilFunctions";
import { apiGetTrainersActivity } from "../../../api/trainersActivity";
import { useNavigate } from "react-router-dom";

const TrainersActivity = ({ positions }) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize navigate function

    const [loading, setLoading] = useState(false);  // Loading state for trainers list
    const [trainers, setTrainers] = useState([]);  // List of trainers
    const [selectedTrainer, setSelectedTrainer] = useState('');  // Selected trainer
    const [startDate, setStartDate] = useState(null);  // Start date state
    const [endDate, setEndDate] = useState(null);  // End date state
    const [activities, setActivities] = useState(false);  // End date state

    // Fetch the trainers list (mocked for now)
    useEffect(() => {
        getTrainers()
    }, []);

    const groupByDateStart = (data) => {
        return data.reduce((acc, curr) => {
            // Extract the date portion (YYYY-MM-DD) from date_start
            const date = new Date(curr.date_start).toISOString().split('T')[0];

            // If the date key doesn't exist in the accumulator, create it
            if (!acc[date]) {
                acc[date] = [];
            }

            // Push the current item into the appropriate date group
            acc[date].push(curr);

            return acc;
        }, {});
    };
    // Function to group by trainer_id
    const groupByTrainerId = (data) => {
        return data.reduce((acc, curr) => {
            const trainerId = curr.trainer_id;

            // If the trainer_id key doesn't exist in the accumulator, create it
            if (!acc[trainerId]) {
                acc[trainerId] = [];
            }

            // Push the current item into the array for the trainer_id
            acc[trainerId].push(curr);

            return acc;
        }, {});
    };

    const getTrainers = async () => {
        const trainersPositionsIds = positions.filter(position => TRAINERS_POSITIONS.includes(position.name.toLowerCase())).map(trainer => trainer.id);
        apiGetEmployeeBy(setTrainers, showErrorToast, `position_id=${trainersPositionsIds}`)
    }

    // Handle form submission
    const handleSubmit = async () => {
        if (!startDate) {
            showErrorToast("Selecteaza ziua de start")
            return;
        }

        if (endDate && startDate > endDate) {
            showErrorToast("Ziua de final nu poate fi inaintea zilei de start")
            return;
        }


        // If only start date is selected, set endDate to 23:59 of the same day
        const formattedStartDate = dayjs(startDate).startOf('day');  // Set time to 00:00
        const formattedEndDate = endDate
            ? dayjs(endDate).endOf('day')  // End date set to 23:59
            : dayjs(startDate).endOf('day');  // If no end date, set to 23:59 of start date


        // Mock submission
        await apiGetTrainersActivity(setActivities, showErrorToast, selectedTrainer === "" ? null : selectedTrainer, formattedStartDate.toISOString(), formattedEndDate.toISOString())

    };

    useEffect(() => {
        if (activities) {
            // console.log("activities: ", activities)
            // console.log(groupByDateStart(activities))
            console.log()
        }
    }, [activities])

    const calculateActivityPercentage = (start, end) => {
        // Normalize the times based on 6 AM (6:00) as the starting point
        const normalizedStart = Math.max(start - 6, 0); // Ensure it's not negative
        const normalizedEnd = Math.min(end - 6, 16);    // Ensure it doesn't exceed 16 (10 PM)

        // Calculate the percentage of the 16-hour window
        return ((normalizedEnd - normalizedStart) / 16) * 100;
    };

    const calculateLeftPosition = (start) => {
        // Normalize start time for 6 AM - 10 PM window
        const normalizedStart = Math.max(start - 6, 0); // Ensure it's not negative
        return (normalizedStart / 16) * 100;  // Calculate the percentage position within the 16-hour window
    };

    const goToTrainersScreen = () => {
        navigate('/dashboard/createTrainersActivity')
    }

    return (
        <div className="space-y-4">
            <Box className="flex flex-row justify-between">
                <Typography variant="h4">
                    <span className="font-bold text-black">{t('Trainers Activity')}</span>
                </Typography>
                <Box>
                    <Button variant="contained" color="success" onClick={goToTrainersScreen}>
                        Create Trainers Activity
                    </Button>
                </Box>
            </Box>

            {/* Loading Spinner */}
            {loading ? (
                <div className="flex justify-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {/* Trainers List */}
                    <Select
                        value={selectedTrainer}
                        onChange={(e) => setSelectedTrainer(e.target.value)}
                        fullWidth
                        displayEmpty
                        className="mb-4"
                    >
                        <MenuItem value="" disabled>{t('Select Trainer')}</MenuItem>
                        <MenuItem value="">{t('All')}</MenuItem>
                        {trainers && trainers.map((trainer) => (
                            <MenuItem key={trainer.id + "select"} value={trainer.id}>
                                {trainer.name}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* Date Pickers */}
                    <div className="space-x-4">
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('Start Date')}
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth className="mb-4" />}
                            /></LocalizationProvider>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('End Date')}
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth className="mb-4" />}
                            />
                        </LocalizationProvider>
                    </div>

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        className="bg-blue-500 hover:bg-blue-700"
                    >
                        {t('Search')}
                    </Button>

                    {activities && trainers && (
                        <div className="mt-6 space-y-4">
                            <Typography variant="h6">{t('Daily Activity')}</Typography>
                            <div className="space-y-10">
                                {Object.keys(groupByDateStart(activities)).map((dateStart) => {
                                    return <div className="font-semibold text-lg">{dateStart}
                                        {trainers
                                            .filter(trainer => { if (selectedTrainer === "") { return true } else { return selectedTrainer === trainer.id } })
                                            .map(trainer => (
                                                <div key={trainer.id + dateStart} className="space-y-2">
                                                    <Typography variant="subtitle1" className="font-bold">{trainer.name}</Typography>
                                                    <div className="relative h-6 w-full bg-gray-200 rounded-md">
                                                        {groupByTrainerId(groupByDateStart(activities)[dateStart])[trainer.id]?.map((activity, index) => (
                                                            <div
                                                                key={index + dateStart + trainer.id}
                                                                className="absolute h-full bg-green-500 rounded-md"
                                                                style={{
                                                                    left: `${calculateLeftPosition(new Date(activity.date_start).getHours())}%`,
                                                                    width: `${calculateActivityPercentage(new Date(activity.date_start).getHours(), new Date(activity.date_end).getHours())}%`
                                                                }}
                                                            >
                                                                <span className="text-xs absolute -top-4 left-1/2 transform -translate-x-1/2">
                                                                    {new Date(activity.date_start).getHours()}:00 - {new Date(activity.date_end).getHours()}:00
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                })}
                            </div>

                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TrainersActivity;