import CategoryIcon from '@mui/icons-material/Category';

export const appMenus = [
    {
        id: 1,
        parentId: null,
        name: "SORTIMENTE",
        to: null,
        isCategory: true,
        icon: CategoryIcon,
        order: 1,
        children: [
            {
                id: 101,
                parentId: 1,
                name: "Services",
                to: '/app/services',
                isCategory: false,
                order: 11,
            },
            {
                id: 102,
                parentId: 1,
                name: "Products",
                to: '/app/products',
                isCategory: false,
                order: 11,
            },
            {
                id: 103,
                parentId: 1,
                name: "Settings",
                to: '/app/assortmentSettings',
                isCategory: false,
                order: 11,
            },
            {
                id: 104,
                parentId: 1,
                name: "Bundles",
                to: '/app/bundles',
                isCategory: false,
                order: 11,
            },
        ]
    },
]