import { useNavigate } from "react-router-dom";
import GenericTable from "../../../components/GenericTable";
import { useEffect, useState } from "react";
import { formatMinutesToString, showErrorToast } from "../../../utils/utilFunctions";
import { apiGetPontaje } from "../../../api/pontaje";

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'employee', headerName: 'Adaugat De', type: 'string' },
    { field: 'for_employee', headerName: 'Angajat', type: 'string' },
    { field: 'time_worked', headerName: 'Ore Lucrate', type: 'string' },
    { field: 'day_off', headerName: 'Concediu', type: 'boolean' },
    { field: 'date', headerName: 'Data Munca', type: 'date' },
    { field: 'sum', headerName: 'Suma', type: 'string' },
    { field: 'created_at', headerName: 'created_at', type: 'datetime' },
];

const Pontaj = ({ selectedLocation }) => {
    const navigate = useNavigate(); // Initialize navigate function
    const [data, setData] = useState([])

    useEffect(() => {
        if (selectedLocation)
            apiGetPontaje(parseData, showErrorToast, selectedLocation.id)
    }, [selectedLocation])

    const parseData= (data)=> {
        data.forEach(dataEntry => {
            dataEntry.time_worked = formatMinutesToString(dataEntry.time_worked)

        })
        setData(data)
    }

    return (
        <>
            <GenericTable title={"Pontaje"} buttonText={"Adauga Pontaj"} buttonAction={() => { navigate('/dashboard/pontaj/createPontaj') }} columns={columns} data={data}></GenericTable>
        </>
    )
}
export default Pontaj;