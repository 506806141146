import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { apiGetEmployeeBy } from '../../../api/employee';
import { formatDateToYYYYMMDD, getHoursWithHalfHourInterval, parseDateForApi, showErrorToast } from '../../../utils/utilFunctions';
import { apiGetFineTypes } from '../../../api/fines';
import { apiCreatePaymentRequest, apiGetPaymentRequestTypes } from '../../../api/paymentRequests';
import { useNavigate } from 'react-router-dom';
import { apiGetUserRightsForLocation } from '../../../api/rights';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Import Adapter for date handling
import { apiCreatePontaj } from '../../../api/pontaje';

const CreatePontaj = ({
    selectedLocation = null,
    user = null,
}) => {
    const navigate = useNavigate(); // Initialize navigate function
    const { t } = useTranslation();
    const [locationEmployees, setLocationEmployees] = useState([])
    const [userRightsForLocation, setUserRightsForLocation] = useState([])

    useEffect(() => {
        if (selectedLocation) {
            apiGetEmployeeBy(setLocationEmployees, showErrorToast, `location_id=${selectedLocation.id}`)
            apiGetUserRightsForLocation(setUserRightsForLocation, showErrorToast, selectedLocation.id)
        }
    }, [selectedLocation])

    useEffect(() => {
        setFormData({
            location_id: selectedLocation?.id || -1,
            employee_id: user?.id || -1,
            for_employee_id: '',
            request_type_id: '',
            sum: null,
            time_worked: '',
            date: new Date,
            day_off: 0,
        })
    }, [user, selectedLocation])

    const [formData, setFormData] = useState({
        location_id: selectedLocation?.id || -1,
        employee_id: user?.id || -1,
        for_employee_id: '',
        request_type_id: '',
        sum: null,
        time_worked: '',
        date: new Date,
        day_off: 0,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleDateChange = (newDate) => {
        setFormData({
            ...formData,
            date: newDate
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await apiCreatePontaj(() => { navigate(-1); }, showErrorToast, {...formData})
    };

    return (
        <>
            <Typography variant="h4">
                <span className="font-bold text-black">Adauga Pontaj</span>
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label={t('Location')}
                    name="location_id"
                    value={selectedLocation?.name || ""}
                    fullWidth
                    margin="normal"
                    disabled // Disabled since this is a fixed value
                />
                <TextField
                    label={t('Employee')}
                    name="employee_id"
                    value={user?.name || ""}
                    fullWidth
                    margin="normal"
                    disabled // Disabled since this is a fixed value
                >
                </TextField>
                <TextField
                    select
                    label={t('For Employee')}
                    name="for_employee_id"
                    value={formData.for_employee_id}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    {locationEmployees.map(employee => (
                        <MenuItem key={employee.id} value={employee.id}>
                            {employee.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Box className="mt-[16px] mb-[8px]">
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                            className="w-full"
                            label="Data"
                            value={formData.date}
                            onChange={handleDateChange}
                            disabled={userRightsForLocation[0]?.right_code !== 3}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Box>
                <TextField
                    label='Suma'
                    name="sum"
                    type="number"
                    value={formData.sum || ''}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                {/* DatePicker for selecting a date */}

                <TextField
                    select
                    label="Ore Lucrate"
                    name="time_worked"
                    value={formData.time_worked}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    {getHoursWithHalfHourInterval().map(entry => (
                        <MenuItem key={`interval-${entry.id}`} value={entry.minutes}>
                            {entry.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Concediu"
                    name="day_off"
                    value={formData.day_off}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    {[{ id: 0, value: 1, name: "Da" }, { id: 1, value: 0, name: "Nu" }].map(entry => (
                        <MenuItem key={`day_off${entry.id}`} value={entry.value}>
                            {entry.name}
                        </MenuItem>
                    ))}
                </TextField>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                        Adauga Cerere
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default CreatePontaj;
