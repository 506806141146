import { getToken } from "../utils/utilFunctions";

export const apiGetEmployeeDetails = async (setEmployeeDetails) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/employeeDetails`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
        } else {
            setEmployeeDetails(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

export const apiGetEmployeeDetailsBy = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/employeeDetailsBy?${reqData}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}


export const apiEditEmployeeDetails = async (responseCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/employees/editEmployeeDetails`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            responseCallback(data)
        } else {
            responseCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        responseCallback({ success: false, message: "Ceva nu a mers bine" })
    }
}