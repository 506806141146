import { getToken } from "../utils/utilFunctions";

export const getProductsAPI = async (successCallBack, errorCallBack, type) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products?type=${type}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            successCallBack([])
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const getProductsWithVariantsAPI = async (successCallBack, errorCallBack, type) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products/withVariants`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            successCallBack([])
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const getProductByIdAPI = async (successCallBack, errorCallBack, id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products?id=${id}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            successCallBack([])
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const createProductAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        // Create a new FormData object
        const formData = new FormData();

        // Append all textual fields from reqData to formData
        Object.keys(reqData).forEach(key => {
            if (key !== 'photo') {  // Exclude the photo from this loop
                formData.append(key, reqData[key]);
            }
        });

        if (reqData.photo) {
            formData.append('photo', reqData.photo); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/products`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const updateProductAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        // Create a new FormData object
        const formData = new FormData();

        // Append all textual fields from reqData to formData
        Object.keys(reqData).forEach(key => {
            if (key !== 'photo') {  // Exclude the photo from this loop
                formData.append(key, reqData[key]);
            }
        });

        if (reqData.photo) {
            formData.append('photo', reqData.photo); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/products`, {
            method: 'PUT', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const deactivateProductAPI = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products`, {
            method: 'DELETE', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack("Ceva nu a mers bine" )
    }
}

export const getProductVariantsAPI = async (successCallBack, errorCallBack, product_id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products/variants?product_id=${product_id}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            successCallBack([])
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const createProductVariantAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        // Create a new FormData object
        const formData = new FormData();

        // Append all textual fields from reqData to formData
        Object.keys(reqData).forEach(key => {
            if (key !== 'photo') {  // Exclude the photo from this loop
                formData.append(key, reqData[key]);
            }
        });

        if (reqData.photo) {
            formData.append('photo', reqData.photo); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/products/variants`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const updateProductVariantAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        // Create a new FormData object
        const formData = new FormData();

        // Append all textual fields from reqData to formData
        Object.keys(reqData).forEach(key => {
            if (key !== 'photo') {  // Exclude the photo from this loop
                formData.append(key, reqData[key]);
            }
        });

        if (reqData.photo) {
            formData.append('photo', reqData.photo); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/products/variants`, {
            method: 'PUT', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}


export const deactivateProductVariantAPI = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/products/variants`, {
            method: 'DELETE', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack("Ceva nu a mers bine" )
    }
}