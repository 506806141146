import React, { useEffect, useState } from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Button, Autocomplete } from '@mui/material';

const BundleItemSettings = ({ bundleItem = {}, setBundleItem = {}, saveBundleItem, products }) => {
    const [variantsToPickFrom, setVariantsToPickFrom] = useState([])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBundleItem((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (bundleItem.product_id && products) {
            setVariantsToPickFrom(products.filter(product => { return product.id === bundleItem.product_id })[0].variants)
        }
    }, [bundleItem]);

    const handleSubmit = (event) => {
        event.preventDefault();
        saveBundleItem(bundleItem);
    };

    const handleProductChange = (event, newValue) => {
        setVariantsToPickFrom(newValue.variants)
        setBundleItem({...bundleItem, product_id: newValue.id})
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={products}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={handleProductChange}
                        value={products.find((product) => product.id === bundleItem.product_id) || null}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Product"
                                margin="normal"
                                required
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="variant-id-label">Variant</InputLabel>
                        <Select
                            label="Variant"
                            labelId="variant-id-label"
                            id="variant_id"
                            name="variant_id"
                            value={bundleItem.variant_id}
                            onChange={handleChange}
                        >
                            {variantsToPickFrom.map(variant => {
                                return <MenuItem value={variant.id}>{variant.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        id="quantity"
                        label="Quantity"
                        name="quantity"
                        value={bundleItem.quantity || ''}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{ min: "1" }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="discount-type-label">Discount Type</InputLabel>
                        <Select
                            label="Discount Type"
                            labelId="discount-type-label"
                            id="discount_type"
                            name="discount_type"
                            value={bundleItem.discount_type || ''}
                            onChange={handleChange}
                        >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="absolute">Absolute</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        required
                        fullWidth
                        type="number"
                        id="discount_value"
                        label="Discount Value"
                        name="discount_value"
                        value={bundleItem.discount_value}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{ step: "0.01", min: "0" }}
                    />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                Save Bundle Item
            </Button>
        </Box>
    );
};

export default BundleItemSettings;
