import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { apiGetEmployeeBy } from "../../../api/employee";
import { showErrorToast } from "../../../utils/utilFunctions";
import { TRAINERS_POSITIONS } from "../../../utils/utilConstants";
import { apiCreateTrainerActivity } from "../../../api/trainersActivity";

const CreateTrainersActivity = ({ positions }) => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const currDate = new Date();
    currDate.setSeconds(0)
    currDate.setMilliseconds(0)
    currDate.setMinutes(0)
    const [startTime, setStartTime] = useState(currDate.toISOString().slice(0, -8));
    const [endTime, setEndTime] = useState(currDate.toISOString().slice(0, -8));
    const [trainers, setTrainers] = useState([]);  // List of trainers
    const [selectedTrainer, setSelectedTrainer] = useState('');  // Selected trainer

    useEffect(() => {
        getTrainers()
    }, [])

    const getTrainers = async () => {
        const trainersPositionsIds = positions.filter(position => TRAINERS_POSITIONS.includes(position.name.toLowerCase())).map(trainer => trainer.id);
        apiGetEmployeeBy(setTrainers, showErrorToast, `position_id=${trainersPositionsIds}`)
    }

    const submit = async () => {
        if (!selectedTrainer) {
            showErrorToast("Selectati trainerul")
        }
        if (!startTime) {
            showErrorToast("Introduceti data de start")
        }
        if (!endTime) {
            showErrorToast("Introduceti data de final")
        }
        const reqBody = {
            date_start: new Date(startTime).toISOString(),
            date_end: new Date(endTime).toISOString(),
            trainer_id: selectedTrainer
        }

        apiCreateTrainerActivity(() => { navigate(-1) }, showErrorToast, reqBody)
    }

    return (
        <div className="space-y-4">
            <Typography variant="h4">
                <span className="font-bold text-black">Create Trainers Activity</span>
            </Typography>

            <Select
                value={selectedTrainer}
                onChange={(e) => setSelectedTrainer(e.target.value)}
                fullWidth
                displayEmpty
                className=""
            >
                <MenuItem value="" disabled>{t('Select Trainer')}</MenuItem>
                {trainers.map((trainer) => (
                    <MenuItem key={trainer.id + "select"} value={trainer.id}>
                        {trainer.name}
                    </MenuItem>
                ))}
            </Select>

            <TextField
                label="Start Time"
                type="datetime-local"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
            />
            <TextField
                label="End Time"
                type="datetime-local"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button onClick={submit} variant="contained" color="primary">
                    Adauga Activitate
                </Button>
            </Box>
        </div>
    )
}
export default CreateTrainersActivity;