import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { apiGetEmployeeBy } from '../../../api/employee';
import { showErrorToast } from '../../../utils/utilFunctions';
import { apiAddOpCard, apiGetFineTypes } from '../../../api/fines';
import { useNavigate } from 'react-router-dom';

const AddOpCard = ({
    selectedLocation = null,
    user = null
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize navigate function
    const [locationEmployees, setLocationEmployees] = useState([])
    const [fineTypes, setFineTypes] = useState([])

    useEffect(() => {
        if (selectedLocation)
            apiGetEmployeeBy(setLocationEmployees, showErrorToast, `location_id=${selectedLocation.id}`)
        apiGetFineTypes(setFineTypes, showErrorToast)
    }, [selectedLocation])

    useEffect(() => {
        setFormData({
            location_id: selectedLocation?.id || -1,
            employee_id: user?.id || -1,
            for_employee_id: '',
            fine_type_id: '',
            price: '',
            paid: false,
            description: ''
        })
    }, [user, selectedLocation])

    const [formData, setFormData] = useState({
        location_id: selectedLocation?.id || -1,
        employee_id: user?.id || -1,
        for_employee_id: '',
        fine_type_id: '',
        price: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        apiAddOpCard(() => { navigate(-1); }, showErrorToast, formData)
    };

    return (
        <>
            <Typography variant="h4">
                <span className="font-bold text-black">Adauga Operatiune Card</span>
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label={t('Location')}
                    name="location_id"
                    value={selectedLocation?.name || ""}
                    fullWidth
                    margin="normal"
                    disabled // Disabled since this is a fixed value
                />
                <TextField
                    label={t('Employee')}
                    name="employee_id"
                    value={user?.name || ""}
                    fullWidth
                    margin="normal"
                    disabled // Disabled since this is a fixed value
                >
                </TextField>
                <TextField
                    select
                    label={t('For Employee')}
                    name="for_employee_id"
                    value={formData.for_employee_id}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    {locationEmployees.map(employee => (
                        <MenuItem key={employee.id} value={employee.id}>
                            {employee.name} ({employee.id})
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label={t('Fine Type')}
                    name="fine_type_id"
                    value={formData.fine_type_id}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                >
                    {fineTypes.map(fineType => (
                        <MenuItem key={fineType.id} value={fineType.id}>
                            {fineType.name} ({fineType.id})
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={t('Price')}
                    name="price"
                    type="number"
                    value={formData.price}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label={t('Description')}
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                        {t('Create Fine')}
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default AddOpCard;
