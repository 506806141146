import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from "../components/Navbar";
import Sidebar from "../components/SideBar";
import { Box, Button, Card, CssBaseline } from '@mui/material';
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
import routes from "./../routes.js";
import { apiCheckLogin } from "../api/auth.js";
import { apiGetAllLocations, apiGetEmployeeLocations } from "../api/locations.js";
import { apiGetDepartments } from "../api/departments.js";
import { apiGetPositions } from "../api/positions.js";
import { NEEDS_UPDATE_STRING, showErrorToast } from "../utils/utilFunctions.js";
import { apiGetRights, apiGetUserRights, apiGetUserRightsForLocation } from "../api/rights.js";
import LoadingBar from "react-top-loading-bar";
import { apiGetEmployeeGroups, apiGetEmployeeIsPartOfGroup } from "../api/employee.js";

const MobileApp = (props) => {
    const navigate = useNavigate(); // Initialize navigate function
    const { t } = useTranslation();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [allLocations, setAllLocations] = useState([]);
    const [employeeLocations, setEmployeeLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);

    const [rights, setRights] = useState([])
    const [userRights, setUserRights] = useState([])
    const [employeeGroups, setEmployeeGroups] = useState([])

    const [progress, setProgress] = useState(0)

    const [navigationButtons, setNavigationButtons] = useState([
        <Button
            color="inherit"
            onClick={() => navigate('/dashboard/index')}
            sx={{
                fontWeight: 'bold', // Makes the text bold
                fontSize: '1.1rem'  // Increases font size
            }}
        >
            GO TO PORTAL
        </Button >
    ])


    useEffect(() => {
        document.body.classList.add("bg-gray-300");
        updateData();
        if (window.innerWidth >= 900) {
            setSidebarOpen(true);
        }

        // Add an event listener for the custom event
        window.addEventListener('loadingProgress', (event) => {
            setProgress(event.detail.progress)
        });

        checkShouldSeeAppSettings()

        return () => {
            document.body.classList.remove("bg-gray-300");
        };
    }, [])

    const checkShouldSeeAppSettings = async () => {
        apiGetEmployeeIsPartOfGroup(() => { }, () => { navigate('/dashboard/inedx') }, "APP")
    }

    const updateData = async (needsUpdate) => {
        if (needsUpdate) {
            if (needsUpdate === NEEDS_UPDATE_STRING.user) {
                checkLogin()
                apiGetEmployeeLocations(setEmployeeLocations)
                apiGetUserRights(setUserRights)
            }
        }
        checkLogin();
    }

    const checkLogin = async () => {
        await apiCheckLogin(navigateToAuth, setUser)
    }

    const navigateToAuth = () => {
        navigate('/auth')
    }

    const handleMenuClick = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/app") {
                const Component = prop.component;
                return (
                    <Route key={`route_${prop.id}`} path={prop.path} element={
                        <Component
                        />
                    } exact />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Box sx={{ display: 'flex', height: '100vh' }}>
                <CssBaseline />

                {/* Sidebar */}
                <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)}
                    selectedLocation={selectedLocation}
                    user={user}
                    userRights={userRights}
                    rights={rights}
                    employeeGroups={employeeGroups} />

                {/* Main Content */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.grey',
                        p: 3,
                        ml: 0, // Offset for the sidebar on desktop
                        mt: 8 // Offset for the navbar
                    }}
                >
                    <Navbar user={user} onMenuClick={handleMenuClick} dropdownItems={employeeLocations} selectedDropdownItem={selectedLocation} setSelectedDropdownItem={() => { }} navigationButtons={navigationButtons} />
                    <Box className="pb-6">
                        <Card className="p-3">
                            <Routes>
                                {getRoutes(routes)}
                                <Route path="*" element={<Navigate to="/app/index" state={{ locations: allLocations, departments: departments }} replace />} />
                            </Routes>
                        </Card>
                    </Box>
                </Box>
            </Box>

            <ToastContainer
                position="top-right"
                autoClose={5000} // Adjust the duration for which the toast should be visible
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};

export default MobileApp;
