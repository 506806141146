import { getToken } from "../utils/utilFunctions";

export const apiGetAllLocations = async (setLocations) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  try {
    const response = await fetch(`${apiUrl}/api/locations/`, {
      method: 'GET', // or 'GET' depending on your APIS
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const data = await response.json();
    if (!data.success) {
    } else {
      setLocations(data.data)
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

export const apiGetEmployeeLocations = async (setLocations) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  try {
    const response = await fetch(`${apiUrl}/api/locations/getLocationsForEmployee`, {
      method: 'GET', // or 'GET' depending on your APIS
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const data = await response.json();
    if (!data.success) {
    } else {
      setLocations(data.data)
    }
  } catch (error) {
    console.error('Error:', error);
  }
}

export const apiGetLocationsForUser = async (successCallBack, errorCallBack) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  try {
    const response = await fetch(`${apiUrl}/api/locations/getLocationsForUser`, {
      method: 'GET', // or 'GET' depending on your APIS
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const data = await response.json();
    if (!data.success) {
      errorCallBack(data.message)
    } else {
      successCallBack(data.data)
    }
  } catch (error) {
    console.error('Error:', error);
    errorCallBack(error);
  }
}

export const apiGetLocationManagers = async (successCallBack, errorCallback, locationId = null) => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  try {
    const response = await fetch(`${apiUrl}/api/locationManagers/getLocationsManagers?${locationId ? `location_id=${locationId}` : ""}`, {
      method: 'GET', // or 'GET' depending on your APIS
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const data = await response.json();
    if (!data.success) {
      errorCallback(data.message)
    } else {
      successCallBack(data.data)
    }
  } catch (error) {
    console.error('Error:', error);
    errorCallback("Something went wrong")
  }
}

export const apiUpdateManagerId = async (successCallBack, errorCallBack, reqData) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();

  try {
    const response = await fetch(`${apiUrl}/api/locationManagers`, {
      method: 'POST', // or 'GET' depending on your APIS
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
          ...reqData
      }),
    });

    const data = await response.json();
    if (!data.success) {
      errorCallBack(data.message)
    } else {
      successCallBack("Successfully updated!")
    }
  } catch (error) {
    console.error('Error:', error);
    errorCallBack("Something went wrong")
  }
}