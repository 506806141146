import { Box, Typography } from "@mui/material";

const NoRights = () => {
    return (
        <Box>
            <Typography variant="h4">
                <span className="font-bold text-black">Nu ai dreptul sa vezi aceasta pagina.</span>
            </Typography>
        </Box>
    )
}

export default NoRights;